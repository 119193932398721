import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Banner } from '../component/Banner'
import { Layout } from '../component/Layout'
import { LifeStyle } from '../component/page_component/LifeStyle'
import { HighLights } from '../component/page_component/HighLights'
import { PropertyList } from '../component/page_component/PropertyList'
import { SearchContext } from '../contexts/searchContext'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import { LocalTaxi } from '@material-ui/icons'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'
import { useLocation } from '@reach/router'
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  highlight: {
    marginTop: theme.spacing(15),
    backgroundColor: '#fff',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(40),
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: theme.spacing(0),
    },
  },
}))

const Buy: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data, types },
  } = props

  const intl = useIntl()
  const classes = useStyles()
    const loc = useLocation();


  const { setSearch, flushSearchState,searchState } = useContext(SearchContext)

  useEffect(() => {
    if (loc?.hash === "#no-update") {

    } else {


      flushSearchState()

      setSearch({ name: 'transactionType', value: 'buy' })
    }

  }, [loc?.hash])

  const hreflangLinks = hreflangConfig['/fr/buy/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const params = queryString.parse(loc.search); // Parse query params
  const currentPage = params.page || 1; // Default to page 1 if not set
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${loc.pathname}${currentPage > 1 ? `?page=${currentPage}` : ""}`
  return (
    <Layout
      showFlotingMenu={false}
      maxWidth={false}
      location={location}
      title={intl.formatMessage({ id: 'PageTitleBuy' })}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="Acheter avec nous - Kretz" />
      </Helmet>

      <Grid container justifyContent="center" id="buy-dream-destination">
        <PropertyList
          bgClr="#FFF"
          filterBackgroundColor={'#FFF'}
          data={data}
          withFilter={true}
          location={location}
          disableTypeFilter={true}
          title={intl.formatMessage({ id: 'new.Location_5' })}
          description={''}
          image={'buyheader.jpg'}
          showCTA={false}
          titleOnly
        />
      </Grid>
      <PreFooterMenu />
    </Layout>
  )
}

export default Buy
